import { type PropsWithChildren } from "react";
import { Helmet } from "react-helmet-async";
import { fCapitalizeFirst } from "../utils/formatters";

interface PageProps {
  title: string;
}

function Page({ title, children }: PropsWithChildren<PageProps>): JSX.Element {
  const cTitle = fCapitalizeFirst(title);

  return (
    <>
      <Helmet>
        <title>{cTitle} | {process.env.REACT_APP_GAME_NAME || "OnlineGames"}</title>
      </Helmet>
      {children}
    </>
  );
}

export default Page;
