import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import useSessionStorage from '../hooks/useSessionStorage';
import { THeResponse, THeAccessToken, TRespSend, TRespReceiver } from '../types';
import { useSocket } from './SocketIo';

const channel = process.env.REACT_APP_API_CHANNEL || "games"

interface IHeGameContext {
  error?: string
  message?: string
  showModal?: boolean
  giftModal?: boolean
  loading?: boolean
  btnLoading?: boolean
  isLoggedIn?: boolean
  subscribed?: boolean
  ipAddress?: string,
  msisdnHash?: string,
  sessionId?: string
  heToken?: string,
  queryParams?: any,
  handleSubscription?: Function
  setShowModal?: Function
  setGiftModal?: Function
  setError?: Function
  clearSession?: Function
  logout?: Function
}

export const HESubscriptionContext = createContext<IHeGameContext>({});

interface IHeGameContextProps {
  children: React.ReactNode;
}

const HeGameProvider: React.FC<IHeGameContextProps> = ({ children }) => {
  const data: IHeGameContext = useHESubscription();

  return (
    <HESubscriptionContext.Provider value={data}>
      {children}
    </HESubscriptionContext.Provider>
  );
}

function useHeGame({ navigate, searchParams }: { navigate: Function, searchParams: URLSearchParams }) {
  appNavigate = navigate;
  appSearchParams = searchParams;
  return useContext(HESubscriptionContext);
}

export { HeGameProvider, useHeGame };

function generateRandomId() {
  const randomString = Math.random().toString(36).substring(2, 26);
  return `${randomString}`;
}

const urlRegex = /^(http|https):\/\/[^\s/$.?#]+\.[^\s]*$/i;

function isValidUrl(string: string) {
  return urlRegex.test(string);
}

function openAndRedirect(url: string, callback?: Function) {
  callback?.()
  // eslint-disable-next-line no-restricted-globals
  location.href = url
}

let appNavigate: Function;
let appSearchParams: URLSearchParams = new URL(document.location.toString()).searchParams

// Hook to manage subscription
function useHESubscription() {
  const queryParams: any = {}

  appSearchParams.forEach((value, key) => {
    queryParams[key] = value;
  });

  const { socket, emit } = useSocket()

  // Session variables
  const [subscribed, setSubscribed, removeA] = useSessionStorage("session-subscribed", false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sessionId, _, removeD] = useSessionStorage("session-id", generateRandomId())

  const clearSession = () => {
    removeA()
    removeD()
  }

  const [isLoggedIn, setIsloggedIn] = useState(!!subscribed && !!sessionId)

  // Component state 
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState<string>()
  const [showModal, setShowModal] = useState<boolean>()
  const [giftModal, setGiftModal] = useState<boolean>()
  const [update, setUpdate] = useState(0)

  // useRef to prevent duplicate calls
  const heToken = useRef<string>()
  const ipAddress = useRef<string>()
  const msisdnHash = useRef<string>()

  function updateState() {
    const val = Math.floor(Math.random() * 1000)
    setUpdate(update + val)
  }

  const ipRequest = useRef(false)

  async function loadIpAddress() {
    if (ipAddress.current) {
      console.log('ip address exists', ipAddress.current)
      return
    }

    if (ipRequest.current) {
      console.log('ip ongoing request, skipping this')
      return
    }

    ipRequest.current = true
    updateState()

    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const { ip } = await response.json();
      ipAddress.current = ip;
    } catch (error) {
      console.log(error);
      setMessage('please check your internet connection')
      setShowModal(true)
    } finally {
      ipRequest.current = false
      updateState()
    }
  }

  const heRequest = useRef(false)

  async function loadHeaderEnrichment(token?: string) {
    if (subscribed) {
      console.log('already subscribed')
      return
    }

    if (msisdnHash.current) {
      console.log('msisdn exists', msisdnHash.current);
      return;
    }

    if (heRequest.current) {
      console.log('ongoing he request, skipping this');
      return;
    }

    heRequest.current = true

    // Add timeout to prevent user from staying for long
    const controller = new AbortController();
    const signal = controller.signal;

    // 10 seconds timeout
    const timeout = setTimeout(() => controller.abort(), 30000);

    try {
      setMessage('Please wait ...');

      const response = await fetch(process.env.REACT_APP_HE_ENDPOINT!, {
        headers: {
          'Authorization': `Bearer ${token || heToken.current}`,
          'Accept-Encoding': 'application/json',
          'Content-Type': 'application/json',
          'X-App': 'he-partner',
          'X-MessageID': '1234',
          'X-DeviceId': '1234',
          'X-Version': '232',
          'X-Source-System': 'he-partner',
        },
        signal,
      });
      const respData: THeResponse = await response.json();
      if (respData.ServiceResponse?.ResponseHeader?.ResponseCode === "200") {
        const hash = respData.ServiceResponse.ResponseBody?.Response?.Msisdn ?? "";
        msisdnHash.current = hash;

        // Page shoult not be /games to prevent infinite redirect
        if (window.location.pathname.startsWith('/games') && ipAddress.current) {
          handleSubscription()
        }
      } else {
        setMessage('Please turn on Safaricom mobile data to continue');
        setShowModal(true);
        // setMsisdnHash("626230225683")
      }
    } catch (error) {
      console.log(error);
      setMessage('Failed to check connectivity');
      setShowModal(true);
    } finally {
      heRequest.current = false;
      updateState()
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const heCBRequest = useRef(false)

  const loadHeaderEnrichmentCB = useCallback(async () => {
    if (subscribed) {
      console.log('already subscribed')
      return
    }

    if (msisdnHash.current && ipAddress.current) {
      console.log('handling subcription', ipAddress)
      handleSubscription()
      return
    }

    if (msisdnHash.current) {
      return
    }

    if (heToken.current) {
      loadHeaderEnrichment()
      return
    }

    if (!socket) {
      console.log('no socket available')
      return
    }

    if (heCBRequest.current) {
      console.log('ongoing heCB request, skipping this')
      return
    }

    heCBRequest.current = true
    updateState()

    // Request for header enrichment
    socket.emit('he-auth')

    // Listen for data
    socket.on('RESPSEND', (data: THeAccessToken) => {
      if (data.status === 400) {
        logout()
        return
      }
      heToken.current = data.access_token
      loadHeaderEnrichment(data.access_token)
      heCBRequest.current = false
      socket.off('RESPSEND')
      updateState()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heToken, loadHeaderEnrichment, msisdnHash, socket]);

  function requestConsent(hash: string, onComplete?: Function) {
    // Cancel prior listens
    socket?.off('RESPSEND')

    // Get consent from user
    socket?.emit('mfilter-consent-gw', JSON.stringify({
      msisdn: hash,
      channel: 'games',
      ip_address: ipAddress.current,
      // source: 'gamesfrenzy.co.ke',
      // eslint-disable-next-line no-restricted-globals
      source: location.host,
      user_agent: navigator.userAgent,
      params: queryParams
    }))

    // Listen for data
    socket?.on('RESPSEND', (data: any) => {
      if (data.status === 400) {
        logout()
        return
      }
      socket?.off('RESPSEND')
      onComplete?.(2)
      // Redirect user
      if (data.message && isValidUrl(data.message)) {
        openAndRedirect(data.message, () => {
          socket.disconnect()
          socket.removeAllListeners()
        })
      }
    })
  }

  const timeoutId = useRef<any>()

  // Loading should not exceed 1 min
  const startLoading = () => {
    if (!loading) {
      setLoading(true);
      timeoutId.current = setTimeout(() => {
        setLoading(false);
        clearTimeout(timeoutId.current)
      }, 60000); // 1 minute in milliseconds
    }
  }

  const seriousError = useCallback((msg: string) => {
    if (socket) {
      socket.off('RESPSEND')
      socket.off('RESPRECEIVER')
    }
    setMessage(msg)
    setLoading(false)
    setShowModal(true)
  }, [socket])

  const socketLoading = useRef(false)

  const handleSubscription = () => {
    if (!socket) {
      console.log('socket still loading')
      socketLoading.current = true
      updateState()
      return
    }

    socketLoading.current = false
    updateState()

    if (msisdnHash.current && ipAddress.current) {
      startLoading()
      setMessage('Please wait')

      if (isLoggedIn) {
        setSubscribed(true)
        setLoading(false)
        updateState()
        return
      }

      // Check if subscribed
      emit('check-subscription', JSON.stringify({
        msisdn: msisdnHash.current,
        // msisdn: "626230225683",
        channel: channel,
        session_id: sessionId,
        params: queryParams,
      }))

      // Listen for response
      socket.on('RESPSEND', (data: TRespSend) => {
        if (data.status === 400) {
          logout()
          return
        }
        const msg = data.msg || data.message
        if (data.subscribed) {
          setSubscribed(true)
          setMessage('Welcome Back')
          setLoading(false)
          setIsloggedIn(true)
          // Go to games
          //appNavigate(`/games`)
          appNavigate(`/games?${appSearchParams.toString()}`)
        } else if (msg === "Subscription Does Not Exists" && ipAddress.current) {
          // Request consent
          if (window.location.pathname.startsWith('/games')) {
            setMessage(msg)
            setShowModal(true)
          } else {
            requestConsent(msisdnHash.current!, () => { })
          }
        } else if (msg === "Error processing your request") {
          seriousError(msg)
        } else if (msg) {
          setMessage(msg)
        }
      });

      // Post subscription event
      socket.on('RESPRECEIVER', (data: TRespReceiver) => {
        if (data.status === 400) {
          logout()
          return
        }
        const msg = data.params?.[0].description
        setLoading(false)
        setMessage(msg)
        setShowModal(true)
        const subscribed = data?.status === 200 && data.params?.[0].subscribed === "0"
        setSubscribed(subscribed)
        const gift = data?.status === 200 && data.params?.[0].gift === true
        if (gift && subscribed) {
          setShowModal(false)
          setGiftModal(true)
          setIsloggedIn(true)
        } else if (subscribed) {
          setIsloggedIn(true)
          appNavigate(`/games?${appSearchParams.toString()}`)
        } else if (msg === "Error processing your request") {
          seriousError(msg)
        }
      });
    } else if (!msisdnHash.current) {
      loadHeaderEnrichmentCB()
    } else if (!ipAddress.current) {
      loadIpAddress()
    } else {
      setMessage("Check your internet and try again")
      setShowModal(true)
    }
  }

  const logout = () => {
    setShowModal(false)
    setLoading(false)
    setIsloggedIn(false)
    setSubscribed(false)
    clearSession()
    appNavigate(`/?${appSearchParams.toString()}`)
  }

  useEffect(() => {
    setError(undefined)
    loadIpAddress()
    loadHeaderEnrichmentCB()

    if (!isLoggedIn) {
      handleSubscription()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const [btnLoading, setBtnLoading] = useState(false)

  useEffect(() => {
    const data = (ipRequest.current || heCBRequest.current || heRequest.current || socketLoading.current)
    setBtnLoading(data)
  }, [update])

  return {
    error,
    message,
    showModal,
    giftModal,
    loading: loading || socketLoading.current,
    btnLoading,
    isLoggedIn,
    subscribed,
    ipAddress: ipAddress.current,
    msisdnHash: msisdnHash.current,
    sessionId,
    heToken: heToken.current,
    handleSubscription,
    setShowModal,
    setGiftModal,
    setError,
    clearSession,
    logout,
    queryParams,
  }
}
